import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Rock from '../../assets/weapons/rock.svg';
import Paper from '../../assets/weapons/paper.svg';
import Scissors from '../../assets/weapons/scissors.svg';
import {
  getSelectWeaponModalStatus,
  setNewGame,
  setSelectWeaponModal,
  setSinglePlayerWeapons,
  setSinglePlayerWinnerMessage,
} from '../../features/game.slice';

export default function SelectWeapon({ singlePlayer, socket }) {
  const dispatch = useDispatch();
  const selectWeaponModalStatus = useSelector(getSelectWeaponModalStatus);
  const weapons = [Rock, Paper, Scissors];
  const selectWeapon = ['rock', 'paper', 'scissors'];

  const handleSinglePlayerWeaponSelection = (index) => {
    const selectedWeapons = {
      singlePlayerWeapon: selectWeapon[index],
      computerWeapon: selectWeapon[Math.floor(Math.random() * weapons.length)],
    };

    dispatch(setNewGame(false));
    dispatch(setSinglePlayerWeapons([selectedWeapons.singlePlayerWeapon, selectedWeapons.computerWeapon]));
    dispatch(setSinglePlayerWinnerMessage(selectedWeapons));
    dispatch(setSelectWeaponModal(false));
  };

  return (
    <Dialog maxWidth="lg" open={selectWeaponModalStatus} style={{ margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
        Select Your Weapon
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        {weapons.map((weapon, index) => (
          <Grid item xs={12} md={4} lg={4} xl={4} key={weapon}>
            <Card>
              <CardMedia
                style={{ height: '260px', width: '220px', margin: '0 auto' }}
                component="img"
                src={weapon}
                onClick={() => handleSinglePlayerWeaponSelection(index)}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
}
