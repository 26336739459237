import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import userProfileImage from '../../assets/userImage.jpeg';

export default function Home() {
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        md={12}
        lg={10}
        xl={8}
        sx={{
          textAlign: 'center',
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', md: 'auto auto' },
          marginTop: '5%',
        }}
      >
        <Box
          component="img"
          src={userProfileImage}
          alt="test"
          sx={{
            marginLeft: '10px',
            width: { xs: '320px', md: '320px', lg: '420px', xl: '500px' },
            height: { xs: '320px', md: '320px', lg: '420px', xl: '500px' },
            borderRadius: '50%',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
            marginRight: '10px',
            display: { xs: 'block', md: 'block', lg: 'block', xl: 'block' },
            margin: { xs: 'auto', md: 'auto', lg: 'auto', xl: 'auto' },
          }}
        />
        <Box
          component="div"
          sx={{
            textAlign: 'center',
            width: { xs: '100%', md: '100%', lg: '100%', xl: '100%' },
            display: { xs: 'block', md: 'block', lg: 'block', xl: 'block' },
          }}
        >
          <Card style={{ boxShadow: 'none' }} sx={{ marginLeft: { xs: '0px', md: '3px', lg: '10px', xl: '10px' } }}>
            <Typography variant="h2" sx={{ fontWeight: 'bolder', fontSize: { xs: 35 }, marginTop: { xs: 3 } }}>
              Adnan Ovčina
            </Typography>
            <Typography variant="h6" sx={{ fontSize: { xs: 20 } }}>
              Full Stack Web Developer and PhD student
            </Typography>
            <CardContent sx={{ fontSize: { xs: '1em', md: '1.5em' }, textAlign: 'left', fontFamily: 'unset' }}>
              {`Hi. My name is Adnan. I am a full stack web developer. I have been working MERN stack for 2 years.
                I have worked with React, Redux, Node, Express, MongoDB, Mongoose, Socket.io and many more. 
                I'm also PhD student at the University of Sarajevo. My field of interest apart from web development is data modelling and data analytics.
                On this page you can find some of my projects. We can also connect on LinkedIn. `}
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
