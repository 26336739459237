import { Grid, Card, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Rock from '../../assets/weapons/rock.svg';
import Paper from '../../assets/weapons/paper.svg';
import Scissors from '../../assets/weapons/scissors.svg';

export default function Weapons({ selectedWeapons }) {
  const dispatch = useDispatch();

  const players = ['Player', 'Computer'];

  return (
    <Grid container justifyContent="center">
      {players.map((player, index) => (
        <Grid
          item
          key={index}
          xs={12}
          md={4}
          lg={4}
          xl={2}
          sx={{ marginTop: { xs: '10px', md: '40px' }, marginLeft: '20px' }}
        >
          <CardMedia
            component="img"
            src={
              selectedWeapons[index] === 'paper'
                ? Paper
                : selectedWeapons[index] === 'rock'
                ? Rock
                : selectedWeapons[index] === 'scissors'
                ? Scissors
                : null
            }
            sx={{ width: '245px', height: '280px', margin: '0 auto' }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
