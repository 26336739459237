import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { playSinglePlayerGame, setSinglePlayerGame } from '../../features/game.slice';

const pages = ['Home', 'My projects', 'Contact', 'Play Game'];

export default function NavBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const gamePlayStatus = useSelector(playSinglePlayerGame);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== '/game' && gamePlayStatus) {
      dispatch(setSinglePlayerGame(false));
    }
  }, [gamePlayStatus]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const navigateToHome = () => {
    navigate('/');
    setAnchorElNav(null);
  };

  const navigateToMyProjects = () => {
    navigate('/projects');
    setAnchorElNav(null);
  };

  const navigateToContact = () => {
    navigate('/contact');
    setAnchorElNav(null);
  };
  const navigateToPlayGame = () => {
    navigate('/game');
    dispatch(setSinglePlayerGame(false));
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navBarButtonFunctions = [
    window.location.pathname !== '/' ? navigateToHome : null,
    navigateToMyProjects,
    navigateToContact,
    navigateToPlayGame,
  ];

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={page} onClick={navBarButtonFunctions[index]}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                onClick={navBarButtonFunctions[index]}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  textTransform: 'none',
                  fontSize: { xs: '12px', md: '18px', lg: '20px', xl: '20px' },
                  fontWeight: 'bolder',
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box>
            <LinkedInIcon
              onClick={() => window.open('https://www.linkedin.com/in/adnanovcina/')}
              fontSize="large"
              sx={{ cursor: 'pointer', marginRight: '10px', fontSize: { xs: '25px', md: '40px' } }}
            />
            <GitHubIcon
              onClick={() => window.open('https://github.com/Adnnann')}
              fontSize="large"
              sx={{ cursor: 'pointer', marginRight: { xs: '10px', md: '50px' }, fontSize: { xs: '25px', md: '40px' } }}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
