import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Weapons from './Weapons';

import {
  playSinglePlayerGame,
  setSelectWeaponModal,
  setNewGame,
  getSinglePlayerWinnerMessage,
  getSinglePlayerWeapons,
  setSinglePlayerGame,
} from '../../features/game.slice';
import SelectWeapon from './SelectWeaponModal';

import PlaySinglePlayerGameButton from './PlaySinglePlayerButton';

const useStyles = makeStyles({
  gameBoardContainer: {
    marginTop: '20px !important',
  },
});

export default function GameBoard() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const singlePlayerWinnerMessage = useSelector(getSinglePlayerWinnerMessage);

  const singlePlayerWeapons = useSelector(getSinglePlayerWeapons);

  const singlePlayerGame = useSelector(playSinglePlayerGame);

  const handlePlayGame = () => {
    dispatch(setSelectWeaponModal(true));
    dispatch(setSinglePlayerGame(true));
    dispatch(setNewGame(true));
  };

  const winnerMessage = useSelector(getSinglePlayerWinnerMessage);

  return (
    <Grid container justifyContent="center" spacing={2} className={classes.gameBoardContainer}>
      <SelectWeapon singlePlayer={singlePlayerGame} />
      {singlePlayerGame && (
        <Typography
          variant="h3"
          sx={{ display: { xs: 'flex', md: 'none', textAlign: 'center', fontSize: '18px', color: 'red' } }}
        >
          {' '}
          {winnerMessage}
        </Typography>
      )}
      <SelectWeapon singlePlayer={singlePlayerGame} />
      <PlaySinglePlayerGameButton handlePlayGame={handlePlayGame} />
      <Weapons selectedWeapons={singlePlayerWeapons} />
      {singlePlayerGame && (
        <Typography
          variant="h3"
          sx={{
            display: { xs: 'none', md: 'flex', textAlign: 'center', fontSize: '3em', color: 'red', marginTop: '10px' },
          }}
        >
          {' '}
          {winnerMessage}
        </Typography>
      )}
    </Grid>
  );
}
