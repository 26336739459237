import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import './App.css';

import MainRouter from './MainRouter';

function App() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <MainRouter />
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
