import { Card, Box, CardActions, CardContent, CardMedia, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import moment from 'moment';
import React from 'react';

const useStyles = makeStyles({
  title: {
    textDecoration: 'none',
    fontSize: '18px',
    color: 'black',
    cursor: 'pointer',
    margin: '0 auto',
  },
  card: {
    marginRight: '5px',
    marginLeft: '5px',
    marginTop: '20px',
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
});

export default function Project({ image, name, date, description, linkToRepo, topics }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={4} md={5} xl={4} component={Card} className={classes.card} sx={{ margin: '0 auto' }}>
      <CardMedia
        component="img"
        src={image}
        style={{ margin: '0 auto' }}
        sx={{ width: { xs: 400, md: 460, lg: 460, xl: 720 }, height: { xs: 320, md: 340, lg: 380, xl: 320 } }}
      />

      <CardActions style={{ textAlign: 'center', marginBottom: '0' }} sx={{ flex: '1 0 auto' }}>
        <Typography component="div" variant="h4" style={{ margin: '0 auto', marginBottom: '0' }}>
          {name}
        </Typography>
      </CardActions>
      <CardContent style={{ textAlign: 'center', marginTop: '0', marginRight: '20px' }}>
        <Typography variant="caption">{topics.map((topic, index) => `${topic} | `)}</Typography>
      </CardContent>

      <Button
        onClick={() => window.open(linkToRepo)}
        variant="contained"
        style={{
          width: '50%',
          margin: '0 auto',
          marginBottom: '10px',
          textTransform: 'none',
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      >
        Go to GitHub Repo
      </Button>
      <Typography variant="caption" style={{ marginLeft: '25px', fontWeight: 'bold' }}>
        Created at: {moment(date).format('LL')}
      </Typography>
    </Grid>
  );
}
