import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './components/core/Home';
import MyProjects from './components/projects/MyProjects';
import ContactMe from './components/general/ContactMe';
import NavBar from './components/core/NavBar';
import GameBoard from './components/game/GameBoard';

export default function MainRouter() {
  return (
    <Router>
      <NavBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<MyProjects />} />
        <Route path="/contact" element={<ContactMe />} />
        <Route path="/game" element={<GameBoard />} />
      </Routes>
    </Router>
  );
}
