import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Project from './Project';
import Loader from '../utils/Loader';
import ProjectImage from '../../assets/projects/projectImage.jpeg';
import AdminDashboard from '../../assets/projects/admin-dashboard.jpg';
import AudioApp from '../../assets/projects/audio-app.jpg';
import BookRecommendation from '../../assets/projects/book-recommendation.jpg';
import BugTracker from '../../assets/projects/bug-tracker.jpg';
import Cookbook from '../../assets/projects/cookbook.jpg';
import DrawingBoard from '../../assets/projects/drawing-board.jpg';
import eLearningPlatform from '../../assets/projects/eLearning-platform.jpg';
import ExpenseTracker from '../../assets/projects/expense-tracker.jpg';
import HangmanGame from '../../assets/projects/hangman-game.jpg';
import LibraryApp from '../../assets/projects/library-app.jpg';
import LoginApp from '../../assets/projects/login-app.jpg';
import LoginWithJwt from '../../assets/projects/login-with-jwt.jpg';
import PolishNotationApp from '../../assets/projects/polish-notation-app.jpg';
import RockPaperScissors from '../../assets/projects/rock-paper-scissors.jpg';
import SnakeGame from '../../assets/projects/snake-game.jpg';
import SocialNetwork from '../../assets/projects/social-network.jpg';
import TypeScriptReact from '../../assets/projects/typescript-react.jpeg';
import UserManagementDashboard from '../../assets/projects/user-management-dashboard.jpg';
import YahtzeeGame from '../../assets/projects/yahtzee-game.jpg';

const images = [
  AdminDashboard,
  AudioApp,
  BookRecommendation,
  BugTracker,
  DrawingBoard,
  eLearningPlatform,
  ExpenseTracker,
  HangmanGame,
  LibraryApp,
  LoginApp,
  LoginWithJwt,
  PolishNotationApp,
  Cookbook,
  RockPaperScissors,
  SnakeGame,
  SocialNetwork,
  TypeScriptReact,
  UserManagementDashboard,
  YahtzeeGame,
];

const useStyles = makeStyles({
  container: {
    margin: '0 auto !important',
    marginTop: '1% !important',
    alignItems: 'stretch !important',
  },
});

export default function MyProjects() {
  const classes = useStyles();

  const [githubData, setGithubData] = useState([]);
  const [githubUser, setGitHubUser] = useState('Adnnann');
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () =>
    fetch(`https://api.github.com/users/${githubUser}/repos`)
      .then((response) => response.json())
      .then((data) => setGithubData(data));

  return (
    <Grid container justifyContent="center" style={{ margin: '0 auto' }}>
      {githubData.length > 0 ? (
        githubData
          .sort()
          .filter((item) => item.topics.length > 0)
          .map((project, index) => (
            <Project
              key={index}
              image={images[index] || ProjectImage}
              name={
                (project.name.includes('-') && !project.name.includes('eLearning')) || !project.name.includes('-')
                  ? project.name.replace(/_/g, ' ').charAt(0).toUpperCase() + project.name.replace(/-/g, ' ').slice(1)
                  : project.name.replace(/_/g, ' ').charAt(0).toLowerCase() + project.name.replace(/-/g, ' ').slice(1)
              }
              date={project.created_at}
              description={project.description || 'No description available'}
              linkToRepo={project.html_url}
              topics={project.topics}
            />
          ))
      ) : (
        <Loader />
      )}
    </Grid>
  );
}
