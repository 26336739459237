import React, { useState, useEffect } from 'react';
import Tappable from 'react-tappable';
import {
  CardActions,
  Button,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  error: {
    verticalAlign: 'middle',
  },

  submit: {
    margin: 'auto !important',
    marginBottom: theme.spacing(2),
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '900 !important',
    fontSize: '24px !important',
    minWidth: '300px !important',
    minHeight: '60px !important',
  },
}));
export default function ContactMe() {
  const classes = useStyles();

  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    subject: '',
    message: '',
    error: {
      status: '',
      email: false,
      firstName: false,
      lastName: false,
      subject: false,
      message: false,
    },
    sendStatus: false,
  });

  const clickSubmit = () => {
    const user = {
      email: values.email || undefined,
      firstName: values.firstName || undefined,
      lastName: values.lastName || undefined,
      subject: values.subject || undefined,
      message: values.message || undefined,
    };

    if (
      user.email === undefined ||
      user.firstName === undefined ||
      user.lastName === undefined ||
      user.subject === undefined ||
      user.message === undefined
    ) {
      setValues({
        ...values,
        error: {
          status: 'Please fill out all fields',
          email: typeof user.email === 'undefined',
          firstName: typeof user.firstName === 'undefined',
          lastName: typeof user.lastName === 'undefined',
          subject: typeof user.subject === 'undefined',
          message: typeof user.message === 'undefined',
        },
      });
    } else {
      axios
        .post(
          window.location.pathname.includes('localhost')
            ? `http://localhost:${process.env.PORT} || 5000/userMessage`
            : `/userMessage`,
          user
        )
        .then((response) => {
          if (response.data.error) {
            setValues({
              ...values,
              error: {
                status: response.data.error,
                email: true,
                firstName: false,
                lastName: false,
                subject: false,
                message: false,
              },
            });
          } else {
            setValues({ ...values, error: '', sendStatus: true });
          }
        })
        .catch((err) => {
          setValues({ ...values, error: JSON.stringify(err) });
        });
    }
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, messageStatus: false, [name]: event.target.value });
  };

  const closeDialog = () => {
    setValues({
      email: '',
      firstName: '',
      lastName: '',
      subject: '',
      message: '',
      error: '',
      sendStatus: false,
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={{ xs: 0, md: 1, lg: 2, xl: 2 }}
      style={{ paddingLeft: '2.5px', paddingRight: '2.5px' }}
    >
      <Grid item xs={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
        <Typography variant="h3" sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
          Contact me
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3}>
        <TextField
          error={values.error.firstName}
          fullWidth
          type="text"
          label="Name"
          id="firstName"
          value={values.firstName}
          onChange={handleChange('firstName')}
          margin="normal"
          sx={{ xs: { marginRight: '20px' } }}
        />
      </Grid>

      <Grid item xs={12} md={3} lg={3} xl={3}>
        <TextField
          error={values.error.lastName}
          fullWidth
          id="lastName"
          type="text"
          label="Last name"
          value={values.lastName}
          onChange={handleChange('lastName')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3}>
        <TextField
          error={values.error.email}
          fullWidth
          type="text"
          label="Email"
          value={values.email}
          onChange={handleChange('email')}
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} md={9} lg={10} xl={9}>
        <TextField
          fullWidth
          error={values.error.subject}
          id="subject"
          type="text"
          label="Subject"
          onChange={handleChange('subject')}
          margin="normal"
          value={values.subject}
        />
      </Grid>
      <br />
      <Grid item xs={12} md={9} lg={10} xl={9}>
        <TextField
          error={values.error.message}
          fullWidth
          multiline
          rows={4}
          id="message"
          type="text"
          label="Message"
          value={values.message}
          onChange={handleChange('message')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={10} lg={10} xl={10}>
        {values.error && (
          <Typography component="p" color="error" style={{ textAlign: 'center' }}>
            {values.error.status}
          </Typography>
        )}
        <CardActions style={{ cursor: 'pointer' }}>
          <Tappable onTap={clickSubmit} style={{ margin: '0 auto' }}>
            <Button
              variant="contained"
              className={classes.submit}
              disabled={values.sendStatus}
              style={{ cursor: 'pointer' }}
            >
              Submit
            </Button>
          </Tappable>
        </CardActions>
      </Grid>

      <Dialog open={values.sendStatus}>
        <DialogTitle>Message sent!</DialogTitle>
        <DialogContent>
          <DialogContentText>Thank you for your message!</DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: '0 auto' }}>
          <Tappable onTap={closeDialog}>
            <Button>Close</Button>
          </Tappable>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
