import { Button, Grid, Box } from '@mui/material';

export default function PlaySinglePlayerGameButton({ handlePlayGame }) {
  return (
    <Grid item xs={12} md={12} lg={12} xl={12}>
      <Box textAlign="center">
        <Button
          variant="contained"
          sx={{
            minHeight: { md: '60px' },
            minWidth: { md: '220px' },
            fontSize: { xs: '1em', md: '2em' },
            textTransform: 'none',
          }}
          onClick={handlePlayGame}
        >
          Play Game
        </Button>
      </Box>
    </Grid>
  );
}
